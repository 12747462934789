.atdl-content {
  @import 'primereact/resources/themes/lara-light-blue/theme';

  .StrategyPanel {
    display: flex;
    flex-wrap: wrap;

    span.input-check {
      margin-right: 15px;
      margin-top: 0;
      text-wrap: nowrap;
      display: flex;
      align-items: center;

      label {
        margin: 0 0 0 10px;
      }
    }

    &[collapsed='COLLAPSED'] {
      display: none;
    }

    &[orientation='HORIZONTAL'] {
      flex-direction: row;
      align-items: stretch;

      & > * {
        margin-left: 10px;

        &:first-child {
          margin-left: unset;
        }
      }
    }

    &[orientation='VERTICAL'] {
      flex-direction: column;
      align-items: flex-start;

      & > * {
        margin-top: 10px;

        &:first-child {
          margin-top: unset;
        }
      }

      .CheckBox {
        margin-top: 23px;
      }
    }

    &[border='LINE'] {
      border: 1px solid #dcdee2;
      border-radius: 12px;
      width: 100%;

      padding: 20px;
    }

    &[border='NONE'] {
      border: none;
    }

    /*
        > * {
          flex: 1 1 0;
        }
    */
  }

  .strategy-title {
    padding-bottom: 15px;
    color: #23282d;
    font-weight: 600;
    font-size: 16px;
  }

  .Control {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .field-block {
      display: flex;
      flex-direction: column;

      .label {
        white-space: nowrap;
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        margin-bottom: 8px;
        color: #262a32;
      }
    }
  }

  .DropDownList {
    .ant-select {
      flex: 1 1 auto;
    }
  }

  .CheckBox {
    padding: 4px 8px;
  }

  .Clock.Control {
    .ant-picker {
      flex: 1 1 auto;
    }
  }

  .Label.Control {
    font-size: 14px;
  }

  .RadioButton {
    padding-left: 8px;
  }

  .submit-button {
    display: flex;
    margin-top: 20px;
    justify-content: flex-start;
    gap: 10px;

    button {
      border-radius: 8px;
      height: 40px;
      line-height: 16px;
      background: #168eff;
      border: #168eff;
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      padding: 6px 18px;
      border: none;
    }
  }

  .Control {
    &[error='FIELD-ERROR'] {
      input,
      select,
      div.p-dropdown.p-component {
        border: 1px solid #f74053;
      }
    }
  }

  .CheckBox,
  .CheckBoxList,
  .RadioButton,
  .RadioButtonList {
    color: #23282d;
    font-size: 14px;
    font-weight: 600;
  }

  .CheckBox {
    height: 40px;
  }

  .toogle-arrow {
    margin-top: 5px;
    width: 16px;
    height: 16px;
    display: flex;
    align-self: center;
    margin-left: 16px;

    cursor: pointer;

    &-rotate {
      transform: rotate(180deg);
    }
  }

  .row {
    display: flex;
    margin: 15px 0;

    .col {
      margin: 0 15px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  label.load-button {
    height: 28px;
    border: 1px solid #c3c7cd;
    border-radius: 4px;
    display: inline-block;

    a {
      text-decoration: none;
      line-height: 28px;
      display: block;
      padding: 0 15px;
      color: #23282d;
    }
  }

  .atdl-number-input {
    position: relative;

    .atdl-number-buttons {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      height: 30px;
      padding: 4px;

      flex-direction: column;

      cursor: pointer;

      span {
        width: 16px;
        height: 11px;
      }
    }
  }

  .ui-DoubleSpinner {
    display: flex;

    > .spinner {
      display: flex;
      height: 30px;
      margin-left: 15px;
      padding: 0;
      flex-direction: column;

      span {
        width: 16px;
        height: 15px;

        display: flex;

        cursor: pointer;
      }
    }
  }

  .pnkl-validation-errors {
    display: block;
  }

  .atdl-alert {
    margin-top: 16px;

    align-items: flex-start;
    padding: 15px 15px 15px 24px;

    background-color: #fff2f0;
    border: 1px solid #ffccc7;

    border-radius: 8px;

    display: flex;

    .atdl-alert-icon {
      color: #ff4d4f;
      margin-right: 15px;
      font-size: 14px;
    }

    &-content {
      line-height: 22px;
    }
  }

  .p-inputnumber {
    height: 40px;
  }
}
